<template>
    <div>
        <div>
          <!-- <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
                Search
              </button>
            </div>
          </div> -->
    
          <div class="form-group">
            <label for="exampleFormControlSelect1"
              ><h5 style="width: 200px">
                <i class="bi bi-person-plus"></i> 셀 보고서 현황
              </h5></label
            >
            <select
              class="form-control"
              id="holiday"
              name="holiday"
              v-model="holiday"
              @change="getTotalAttend()"
            >
              <option id="" value="00">날짜를 선택하세요.</option>         
              <option
                v-for="daylist in dayLists"
                :key="daylist.dday"
                v-bind:value="daylist.dday"
                :id="daylist.dday"
                :selected="daylist.dday == daylist.cday"
              >
                {{ daylist.dday }}
              </option>
            </select>          
          </div>
        </div>
    
    <!--  재적 인원 -->
    <div style="height: 20px;"></div>
    <!--  출석 인원 -->
    <div class="card">
      <h5 class="card-header">보고서</h5>
      <div class="card-body">
        <h5 class="card-title">{{gradefullname}}</h5>
        <p class="card-text">
          <table class="table table-bordered" v-for="item in enrollList" v-bind:key="item" >
              <colgroup>
                  <col width=20%>
                  <col width=25%>
                  <col width=30%>
                  <col width=20%>
              </colgroup>              
              <thead>
                <tr style="text-align: center;">
                  <th scope="col">
                      <div  v-if="item.photo && item.photo.length > 0" class="form-group  mb-2">
                          <img :src="photourl(item.photo)" alt="..." class="img-thumbnail" :style="profileimageStyle">
                        </div>                   
                        <div  v-else class="form-group mx-sm-3 mb-2" style=" text-align: left;">
                          <img src="../assets/noimage.png" style="width: 30%;">
                        </div>   
  
                  </th>
                  <th scope="col">{{item.studentname}}</th>
                  <th scope="col">{{item.teachername}}</th>
                  <th scope="col">
                    <!-- <button v-if="item.note !== null" type="button" class="btn btn-secondary btn-sm"  @click="feedback(item)">피드백</button>
                    <button v-else type="button" class="btn btn-primary btn-sm" @click="feedback(item)">피드백</button> -->
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="text-align: left;">
                  <td colspan="4">{{item.pray}}</td>                
                </tr>

                <tr style="text-align: left;" v-if="item.report !== null ">
                <td colspan="3" style="color:blue;">셀 모임 진행 및 특이사항</td>                
              </tr>
              <tr style="text-align: left;" v-if="item.report !== null ">
                <td colspan="3" v-html="getContent(item.report)"></td>                
              </tr>

                <tr style="text-align: left;" v-show="item.note !== null">
                  <td colspan="4" style="color: blue;">{{item.note}}
                    <span class="image-container" v-if="item.readyn == '1'"><img src="../assets/read1.png" alt="..."  :style="imageStyle"></span>
                  </td>                
                </tr>              
              </tbody>
            </table>   
        </p>
      </div>
    </div>
  
    
  
    </div>
  
    <div
    class="modal fade"
    id="reportModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="reportModalLabel"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="reportModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span>
              <div>교역자 피드백</div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="addReportForm"
                name="addReportForm"
                v-on:submit="addReportForm"
              >
                <div class="form-group mx-sm-3 mb-2">
  
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">{{form.name}}</label>
                  <textarea
                    class="form-control"
                    id="pl"
                    name="note"
                    rows="7"
                    v-model="form.note"
                    required
                    placeholder="리더 보고서에 대한 피드백을 등록해주세요."
                  ></textarea>
                  <input type="hidden" name="no" v-model="form.no">
                  <input type="hidden" name="studentid" v-model="form.studentid">
                  <input type="hidden" name="teacherid" v-model="form.teacherid">
                  <input type="hidden" name="attenddate" v-model="holiday">
                  <input type="hidden" name="classid" v-model="form.classid">
                  <input type="hidden" name="groupid" v-model="form.groupid">
                  <input type="hidden" name="grade" v-model="form.grade">
                </div>
                   <div class="modal-footer">
                  <button type="submit" class="btn btn-primary" v-if="isNew">
                    등록
                  </button>
                  <button type="submit" class="btn btn-primary" v-else>
                    수정
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <!-- <span>※.</span> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </template>      
  
  <script>
      import manageService from "../services/manageService";
      import $ from "jquery";
      
      export default {
        name: "list",
        component: {},
        data() {
          return {
              enrollList: {},  
              attendLists : {},          
              pageTotalCount: 0,
              page: 15,
              holiday: "00",
              dayLists: {},     
              currentDept: "",            
              id: this.$store.state.id,
              grade: this.$store.state.grade,
              phone: this.$store.state.phone,
              name: this.$store.state.name,
              teacherid: this.$store.state.teacherid,
              role: this.$store.state.role,
              token: this.$store.state.token,  
              classid: this.$store.state.classid,
              studentid: this.$store.state.studentid,
              gradefullname : "",        
              form: {
                    no: "",
                    studentid: "",
                    teacherid: "",
                    attenddate: "",
                    pray: "",
                    name: "",
                    sex: "",
                    classid: "",
                    groupid: "",
                    grade: "",
                    note: "",
                    report: "",
          },               
          isNew: false,
          imageWidth: 30,  
          profileimgWidth: 100,   
          };
        },
        created() {
  
        },
        computed: {
          photourl() {
            return (url) => {
              return "./upload/photo/"+url;
              };
            },     
            imageStyle() {
                return {
                  width: `${this.imageWidth}px`,
                  height: `${this.imageHeight}px`,
                  border: 'none',
                }
              },                 
        },
        methods: {
          getContent(text){
            if(text){
                return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
          },          
          feedback(item){
         //리포트 보고서
          this.isNew = true;
          this.form.studentid = item.studentid;
          this.form.teacherid = item.teacherid;
          this.form.no = item.no;
          this.form.attenddate = item.attenddate;
          this.form.note = item.note;
          this.form.name = item.name;
          this.form.sex = item.sex;
          this.form.classid = this.classid;
          this.form.groupid = this.groupid;
          this.form.grade = this.grade;
          this.form.report = this.report;
  
          $("#reportModal").modal("show");
          },
  
          addReportForm(e) {   //피드백 등록
          e.preventDefault();
          var object = {};
          var fform = document.getElementById("addReportForm");
          var form = new FormData(fform);
          form.forEach(function (value, key) {
            object[key] = value;
          });
  
          var jsondata = JSON.stringify(object);
  
          //console.log('jsondata', jsondata);
          // const data = {
          //   no: JSON.parse(jsondata).no,
          //   classid: JSON.parse(jsondata).classid,
          //   studentid: JSON.parse(jsondata).studentid,
          //   grade: JSON.parse(jsondata).grade,
          //   sector: "1",
          //   holiday: this.holiday,
          //   teacherid: JSON.parse(jsondata).teacherid,
          //   groupid: JSON.parse(jsondata).groupid,
          //   checkyn: "",
          // };
  
          if (JSON.parse(jsondata).note.length < 2) {
            this.$toast.show("내용을 입력해주세요.", {
              type: "warning",
              position: "top-right",
            });
            return;
          }
          //신규 모드
          manageService
            .weeklyreportWrite(jsondata)
            .then(() => {
              this.$toast.show("피드백이 등록되었습니다.", {
                type: "success",
                position: "top-right",
              });
              this.getTotalAttend();
              $("#reportModal").modal("hide");
            })
            .catch((e) => {
              console.log(e);
              this.$toast.show("피드백이 등록되지 않았습니다.", {
                type: "warning",
                position: "top-right",
              });
            });
        },        
          getNum(val) {
              if (isNaN(val)) {
                return 0;
              }
              return val;
          },
          getDayList(){      //날짜 조회
              manageService
              .getDaysList()
              .then((response) => {
  
              this.dayLists = response.data.slice(0, this.page);        
              })
              .catch((e) => {
              console.log(e);
              });
              },
  
          getTotalAttend(){     //전체 레포트
  
            if(this.holiday === "00") return;
  
            console.log("studentid =", this.studentid);

              const data = {
                  attenddate: this.holiday,
                  classid: this.classid,
                  studentid: this.studentid,
                  };
    
              manageService
              .getclassstaffreport(data)
              .then((response) => {
  
              this.enrollList = response.data;   
              //console.log(response.data);
                    
              })
              .catch((e) => {
              console.log(e);
              });
          },
      },   
        mounted() {
          //this.getTotalAttend(); //전체 출석
          this. getDayList(); //날짜 조회
        },
      };
      </script>
      
      <style scoped>
      .list {
        text-align: left;
        max-width: 750px;
        margin: auto;
      }
      .done {
        background: oldlace;
      }
      .card-body {
        padding: 0.5rem;
      }
      .table td, .table th {
          padding: 0.2rem;
          vertical-align: middle;
      }    
      @media (max-width: 600px) {
        .image-container img {
          width: 25px;
        }
      }    
      </style>
      